import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import leftContentImage from '../assets/images/santander-partido-left-image.jpg';
import headerContentImage from '../assets/images/santander-partido-content-header.jpg';
import contentImage from '../assets/images/santander-partido-content.jpg';
import '../styles/PageSantanderPartido.scss';
import { fontawesome } from '../utilities';

const PageSantanderPartido = () => {
    const { solidIcons, brandIcons } = fontawesome;
    return (
        <main id='santander-partido-content' className="santander-partido">
            <section id='header-section'>
                <div className="container header-container">
                    <img src={headerContentImage} alt="Real Santander vs Deportes Quindio header" title="Imagen header Real Santander vs Deportes Quindio" />
                </div>
            </section>
            <section id="content-section">
                <div className="container content-container">
                    <div className='row' >
                        <div className='col-12 col-md-4 content-container__left-content'>
                            <img className='img-fluid' src={leftContentImage} alt="Real Santander vs Deportes Quindio left content" title="Imagen izquierda Real Santander vs Deportes Quindio" />
                            <div className='content-container__left-content__btn-container'>
                                <Link to={'https://ventas.quickticket.com.co/?ev=a3887kcnu2qs0k9echy1yso5brnijw&pven=1'}><button className='btn btn-primary btn-buy-ticket'><FontAwesomeIcon icon={solidIcons['faTicket']} /> COMPRAR</button></Link>
                                <Link to={'http://wa.link/jh91my'}><button className='btn btn-primary btn-buy-information'><FontAwesomeIcon icon={brandIcons['faWhatsapp']} /> INFORMACIÓN</button></Link>
                                <Link to={'https://ventas.quickticket.com.co/perfil.html?tab_seleccionado=mis-compras'}><button className='btn btn-primary btn-buy-download'>DESCARGA TU BOLETA</button></Link>
                            </div>

                        </div>
                        <div className='col-12 col-md-8 content-container__right-content'>
                            <h2 className='content-container__right-content__title'>REAL SANTANDER VS BARRANQUILLA FC</h2>
                            <img className='img-fluid content-container__right-content__content-image' src={contentImage} alt="Real Santander vs Deportes Quindio content" title="Imagen contenido Real Santander vs Deportes Quindio" />
                        </div>
                    </div>
                </div>
            </section>
        </main>
    );
};
export default PageSantanderPartido;